import React from 'react';
import Layout from '../../layout/Layout/Layout';
import * as styles from './not-found.module.scss';
import { classNames } from '../../../utils';
import { Button } from '../../components/Button/Button';


export default function NotFound() {
  return (
    <Layout hasFooter={ false }>
      <div className={ classNames( styles.notFound, 'has-gradient-bg' ) }>
        <h1 className={ styles.notFoundTitle }>
          <p>404</p>
          <p>Not Found</p>
        </h1>

        <Button icon="arrow" isPrimary to="/">Back to Home</Button>
      </div>
    </Layout>
  );
}